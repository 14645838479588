@import url("https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&family=Raleway:wght@300;400;600&display=swap");

body {
  font-family: "Raleway", sans-serif;
}

.sidebar {
  position: fixed;
  max-width: 16%;
}
.mainColorBackground {
  background-color: #9c3984 !important;
}

.sidebarButton {
  border-radius: 0px;
  background-color: transparent;
  border: none;
}

.sidebarButton.active {
  border-radius: 0px;
  background-color: #75135d !important;
  border: none !important;
}

.sidebarButton:hover {
  border-radius: 0px;
  background-color: #75135d !important;
  border: none !important;
}

.sidebarButton:active {
  border-radius: 0px;
  background-color: #75135d !important;
  border: none !important;
}

.sidebarButton:focus {
  border-radius: 0px;
  background-color: #75135d !important;
  border: none !important;
  box-shadow: none !important;
}

.mainButton {
  background-color: #9c3984;
  border: none;
}

.mainButton.active {
  background-color: #75135d !important;
  border: none !important;
}

.mainButton:hover {
  background-color: #75135d !important;
  border: none !important;
}

.mainButton:active {
  background-color: #75135d !important;
  border: none !important;
}

.mainButton:focus {
  background-color: #75135d !important;
  border: none !important;
  box-shadow: none !important;
}

.borderedButton {
  color: #9c3984;
  background-color: white;
  border: 1px solid #9c3984;
}

.borderedButton.active {
  background-color: #75135d !important;
  border: none !important;
}

.borderedButton:hover {
  background-color: #75135d !important;
  border-color: #75135d !important;
}

.borderedButton:active {
  background-color: #75135d !important;
  border-color: #75135d !important;
}

.borderedButton:focus {
  background-color: #75135d !important;
  border-color: #75135d !important;
  box-shadow: none !important;
}

.borderedButtonRed {
  color: red;
  background-color: white;
  border: 1px solid red;
}

.borderedButtonRed.active {
  background-color: rgb(250, 63, 63) !important;
  border: none !important;
}

.borderedButtonRed:hover {
  background-color: rgb(250, 63, 63) !important;
  border-color: rgb(250, 63, 63) !important;
}

.borderedButtonRed:active {
  background-color: rgb(250, 63, 63) !important;
  border-color: rgb(250, 63, 63) !important;
}

.borderedButtonRed:focus {
  background-color: rgb(250, 63, 63) !important;
  border-color: rgb(250, 63, 63) !important;
  box-shadow: none !important;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}
